body {
  background-color: black;
}

.animation {
  position: relative;
}

.controls {
  background-color: rgba(201, 201, 201, 0.6);
  margin: 15px 15px 0;
  position: absolute;
  top: 0;
  width:  200px;
  padding: 50px 25px 0;
}